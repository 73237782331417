@import "vars"

@mixin page-header
  font-family: "Futura PT", sans-serif
  font-weight: 700
  font-size: 4rem
  letter-spacing: 0.02em

  margin-bottom: 3rem

  @media (max-width: $sm)
    font-size: 2.5rem
    margin-bottom: 2rem

  /* @media (max-width: $xs)
    font-size: 2rem */
  
.hide 
  display: none

.show 
  display: block

.#{'hide-'} 
  &lg 
    @media (min-width: $lg)
      display: none !important
  &xl
    @media (min-width: $xl)
      display: none !important

  &xxl
    @media (min-width: $xxl)
      display: none !important

.#{'show-'}
  &lg 
    @media (min-width: $lg)
      display: block !important
  &xl
    @media (min-width: $xl)
      display: block !important
  
  &xxl
    @media (min-width: $xxl)
      display: block !important