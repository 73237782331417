@import "vars"

#rootlet
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-right: -1px
  z-index: $z-rootlet
  background: $bg
  top: 0  // for sticky header

  > .logo
    transform: rotate(-90deg)
    white-space: nowrap
    line-height: 28px
    font-weight: 600
    font-family: "Futura PT", sans-serif
    text-decoration: none
    color: white
    font-size: 22px

  > .contact
    padding: 1rem 0 2rem

    > img
      display: block
      margin: 0 auto

  @media (max-width: $md)
    width: 100%
    height: auto
    flex-direction: row-reverse
    align-items: center
    padding: 0 $mobile-wrapper-padding
    position: fixed
    z-index: $z-rootlet-mobile
    transition-duration: .5s

    &.rootlet_transparent
      background: none
    
    > .logo 
      transform: none

.menu-btn
  position: relative

  background: none
  outline: none
  border: none
  color: white
  text-transform: uppercase
  cursor: pointer
  padding-top: 3.5rem

  @media (max-width: $md)
    padding: 0
    margin: 1rem 0
    width: 3rem
    height: 3rem
    border: 1px solid white
    border-radius: 50%

  &__txt
    @media (max-width: $md)
      display: none

  span
    position: absolute
    width: 2rem
    height: 2px
    top: 50%
    left: 50%
    background: white
    overflow: hidden
    transition: all 0.3s linear
    margin: auto

    &::before
      width: 0
      height: 100%
      top: 0
      right: 0
      background: white
      transition: all 0.3s linear

    &:nth-child(1)
      animation: span-first-off 0.5s 
      animation-fill-mode: forwards

      @media not all and (min-resolution: 0.001dpcm) // media for safari only
        animation-duration: 0s

    &:nth-child(2)
      animation: span-second-off 0.5s 
      animation-fill-mode: forwards

      @media not all and (min-resolution: 0.001dpcm)
        animation-duration: 0s

    &:nth-child(3)
      animation: span-third-off 0.5s 
      animation-fill-mode: forwards

      @media not all and (min-resolution: 0.001dpcm)
        animation-duration: 0s


  &.-cross
    &:hover span::before
      width: 100%
      transition: all 0.3s linear

    span
      &:nth-child(1)
        animation: span-first-on 0.5s 
        animation-fill-mode: forwards

        @media not all and (min-resolution: 0.001dpcm)
          animation-duration: 0s

      &:nth-child(2)
        animation: span-second-on 0.5s 
        animation-fill-mode: forwards

        @media not all and (min-resolution: 0.001dpcm)
          animation-duration: 0s

      &:nth-child(3)
        animation: span-third-on 0.5s 
        animation-fill-mode: forwards

        @media not all and (min-resolution: 0.001dpcm)
          animation-duration: 0s

@keyframes span-first-on
  0%
    transform: translate(-50%, -300%)
  30%
    transform: translate(-50%, -50%)
  100%
    transform: translate(-50%, -50%) rotate(-45deg)

@keyframes span-first-off
  0%
    transform: translate(-50%, -50%) rotate(-45deg)
  30%
    transform: translate(-50%, -50%) rotate(0deg)
  100%
    transform: translate(-50%, -300%)

@keyframes span-second-on
  0%
    transform: translate(-50%, -50%)
  50%
    transform: translate(-50%, -50%) 
  100%
    transform: translate(-150%, -50%) scale(0)

@keyframes span-second-off
  0%
    transform: translate(-150%, -50%) scale(0)
  50%
    transform: translate(-50%, -50%) 
  100%
    transform: translate(-50%, -50%)

@keyframes span-third-on
  0%
    width: 1rem
    transform: translate(-100%, 200%)
  30%
    transform: translate(-50%, -50%)
    width: 2rem
  100%
    width: 2rem
    transform: translate(-50%, -50%) rotate(45deg)

@keyframes span-third-off
  0%
    width: 2rem
    transform: translate(-50%, -50%) rotate(45deg)
  30%
    width: 2rem
    transform: translate(-50%, -50%) rotate(0deg)
  100%
    width: 1rem
    transform: translate(-100%, 200%)

.rootlet_mobile-hidden 
  @media (max-width: $md)
    transform: translateY(-100%)

.rootlet_mobile-visible 
  @media (max-width: $md)
    transform: translateY(0)
  