@import "vars"
@import "common"
  
html, body
  height: 100%
  width: 100%
  background: #000
  color: #fff
  font-family: 'Futura PT Book', sans-serif
  font-size: 1rem

  @media (min-width: $xxl) 
    font-size: 1.1rem

  @media (max-width: $xl)
    font-size: .9rem

*
  line-height: 120%
  box-sizing: border-box

section
  @media (max-width: $md)
    margin-bottom: 5rem

body
  @media (min-width: $md) 
    display: flex
    overscroll-behavior: none
    overflow: hidden

    #scroll-container
      position: absolute

a 
  text-decoration: none

#rootlet
  width: $rootlet-width
  height: 100%
  box-sizing: border-box
  border-right: 1px solid $border

.semi-screen
  @media (min-width: $md)
    width: calc(100vw - #{$rootlet-width})

.page-header
  @include page-header

.desktop 
  position: relative
  @media (min-width: $md) 
    position: static

.slide-item

  @media (min-width: $md) 
    height: 100%
    display: flex
    position: absolute
    width: calc(#{$view-width})
    left: calc((var(--i, 100)) * #{$section-header-width - 3px})
    transform: translate3d(calc(#{$view-width}), 0, 0)
    overflow: hidden

    .riser
      opacity: 0
      transform: translateX(2rem)

      transition: all .5s ease-in
      transition-delay: calc(var(--d, 0) * .2s + .2s)

    &.-shown .riser
      opacity: 1
      transform: translateX(0)


    &.-animated
      transition: $slide-transition

    &.-active
      transform: translate3d(0, 0, 0)

    &.-right
      transform: translate3d(100vw, 0, 0)

    &.-left
      transform: translate3d(-100vw, 0, 0)

.section-header
  cursor: pointer
  position: absolute
  width: $section-header-width - 2px
  border: 1px solid $border
  box-sizing: border-box
  border-top: none
  border-bottom: none
  height: 100%
  background: #000
  z-index: $z-section-header
  left: calc((var(--i, 100)) * #{$section-header-width - 3px})
  transition: $slide-transition

  &.-right
    transform: translate3d(calc(#{$view-width}), 0, 0)

    .title .caret
      opacity: 0

  &.-left
    transform: translate3d(0, 0, 0)

  a
    text-decoration: none
    color: white

  &:hover
    &::before
      content: ''
      display: block
      height: .5rem
      width: .5rem
      left: 50%
      background: $accent-color
      margin: $gutter auto 0
      position: absolute

      transform: rotate(45deg) translateX(-50%)

    .title
      margin-top: 2 * $gutter

  .title
    background: black
    font-family: "Futura PT Demi", sans-serif
    margin-top: $gutter
    transform: rotate(90deg)
    white-space: nowrap
    font-weight: 600
    font-size: 1.25rem
    text-transform: uppercase
    transition: margin-top .2s ease-in

    &::before
      content: attr(data-text)
      position: absolute

    > .caret
      transition: opacity $slide-transition-duration linear
      background: black
      overflow: hidden
      display: inline-block
      transform: rotate(.5turn)
      opacity: 1

  .bottom
    font-family: "Futura PT Book", sans-serif
    position: absolute
    text-align: center
    bottom: 35px
    width: 100%
    font-size: 1.25rem

    > .sect
      display: block
      color: $accent-color
      margin-bottom: .5rem

    > .index
      display: block

      &::before
        content: '0'

  @media (max-width: $md) 
    display: none

.screen-view
  @media (min-width: $md)
    width: calc(#{ $view-width })
    box-sizing: border-box
  
    &.-padded
      padding: $view-padding
  
    &.-full-height
      height: 100vh

.bold
  font-family: "Futura PT Demi", sans-serif
  font-weight: 600
  letter-spacing: .02em

.promo-text
  font-family: "Futura PT Book", sans-serif
  font-size: 1.5rem

.caption
  font-family: "Futura PT Book", sans-serif
  color: white
  font-size: 1.5rem

.body-text
  font-family: "Futura PT Book", sans-serif
  color: #B3B3B3
  font-size: 1.125rem

#scroll-container
  top: 0
  right: 0
  height: 100%
  overflow: hidden

.d-none
  display: none !important

.d-flex
  display: flex

  &.-row
    flex-direction: row

body
  scrollbar-color: white transparent
  scrollbar-width: thin

::-webkit-scrollbar
  background: transparent
  width: 4px

::-webkit-scrollbar-button
  display: none

::-webkit-scrollbar-track
  background: transparent

::-webkit-scrollbar-track-piece
  background: transparent

::-webkit-scrollbar-thumb
  background: white
  border-radius: 2px

.mobile-d-none 
  @media (max-width: $md)
    display: none !important