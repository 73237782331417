@import "vars"

.main-page
  @media (max-width: $md)
    padding-top: 2rem
    display: flex
    flex-direction: column
    
  .content
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 5%

    padding: 80px
    z-index: 1

    @media (max-width: $md)
      padding: 0 $mobile-wrapper-padding
      gap: 1rem

    > .title
      font-size: 7rem
      letter-spacing: .03rem
      font-family: 'Futura PT Demi', sans-serif

      @media (max-width: $sm)
        font-size: 5rem

      @media (max-width: $xs)
        font-size: 2.5rem


  .description
    width: calc(#{6.47 * 7rem})
    word-spacing: -1px

    color: $second-text
    text-align: justify
    font-size: 24px
    line-height: 32px

    @media (max-width: $md)
      width: 70%
      line-height: 1.2
      text-align: start 

    @media (max-width: $sm)
      font-size: 1rem

  .contacts
    svg 
      width: 100%
      height: 100%

    #hoverable-circle
      fill: transparent
      transition: fill .2s linear

    &:hover #hoverable-circle
      fill: $accent-transparent

    @media (max-width: $md)
      width: 6rem
      margin-top: 1rem

  > .img-container
    position: absolute
    flex-grow: 1
    bottom: 0
    top: 13%
    right: -20px
    transition: margin-left $slide-transition-duration ease-in

    @media (max-width: $md)
      position: relative

      bottom: unset
      right: unset

    > .img
      width: 100%
      height: 100%
      object-fit: contain
      object-position: right

      @media (max-width: $md)
        position: relative
        margin-top: -30%
        width: 153%
        right: 15%
      
      @media (max-width: $sm)
        margin-top: -36%
        right: 20%
        width: 163%

  .arrow-button
    margin-left: 16px

#main.-left .img-container
  @media (min-width: $md)
    margin-left: -$statue-margin

.scroll-tip
  font-weight: 600
  font-size: 22px
  line-height: 48px
  color: white
  text-decoration: none

  @media (max-width: $md)
    display: none

  .icon
    display: inline-flex
    vertical-align: middle
    justify-content: center
    align-items: center

    margin-left: 16px
    height: 48px
    width: 48px


    border: 0.7px solid rgba(255, 255, 255, 0.5)
    border-radius: 50%

    background: transparent
    transition: background .2s linear

    &:hover
      background: $accent-transparent

.contacts 
  svg
    overflow: visible // уродливое временное решение, чтобы задеплоить, потом сделаю нормально
